svg.custom circle {
  fill: #4d0026;
  stroke: #4d0026;
  stroke-width: 4px;
  r: 12px;
}

svg.custom path.link {
  fill: none;
  stroke: #277500;
  stroke-width: 4px;
}
svg.custom {
  font-size: 25px;
  font-weight: 600;
  writing-mode: vertical-rl;
  text-orientation: mixed;
} 

div.custom-container {
  text-align: center;
}

svg {
  transform: rotate(270deg);
}
