h2 {
  text-align: center;
  font-size: 190%;
  color: #277500;
}

form {
  width: 300px;
  margin: 60px auto 30px;
  padding: 10px;
  box-shadow: 
    0 0 1px rgba(0, 0, 0, 0.3), 
    0 3px 7px rgba(0, 0, 0, 0.3), 
    inset 0 1px rgba(255,255,255,1),
    inset 0 -3px 2px rgba(0,0,0,0.25);
  border-radius: 2px;
  background: linear-gradient(#eeefef, #ffffff 10%);
}

input[type=text], input[type=password], input[type=date], select {
  width: 100%;
  padding: 12px 15px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
}

select {
  padding: 10px 15px;
}

textarea {
  -webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	width: 100%;
  height: 150px;
  padding: 12px 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: none;
}

button[type=submit], .cancel {
  width: 100%;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 700;
}

button[type=submit] {
  background-color: #3bb300;
}

.cancel {
  background-color:   #8c8c8c;
}

.cancel:hover {
  background-color: #4d4d4d;
}

button[type=submit]:hover {
  background-color: #277500;
}

label {
  padding: 0 0 5px 2px;
  font-weight: 500;
}

.red {
  color: red;
}

@media only screen and (min-width: 769px) {
  h2 {
    font-size: 210%;
  }

  form {
    width: 370px;
  }
  label {
    font-size: 20px;
  }
}

@media only screen and (min-width: 1140px) {
  h2 {
    font-size: 230%;
  }

  form {
    width: 420px;
  }
}


